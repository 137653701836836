import { Stack, StackProps } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';

type CasStackRowProps = {
  children: React.ReactNode;
  justifyContent?: StackProps['justifyContent'];
  alignItems?: StackProps['alignItems'];
  flexGrow?: StackProps['flexGrow'];
  gapSize?: 'none' | 'small' | 'large';
  indent?: number;
  className?:StackProps['className'];
  backgroundColor?: string;
};

export function CasStackRow({
  children,
  gapSize = 'small',
  indent = 0,
  backgroundColor,
  ...rest
}: CasStackRowProps) {
  const gapMap = {
    none: 0,
    small: 1,
    large: 5,
  };

  const marginLeft = 2 * indent;
  const smDown = useResponsive('down', 'sm');

  return (
    <Stack
      flexDirection="row"
      gap={gapMap[gapSize]}
      ml={marginLeft}
      flexWrap={smDown ? 'wrap' : 'nowrap'}
      sx={backgroundColor ? { backgroundColor } : undefined}
      {...rest}
    >
      {children}
    </Stack>
  );
}
