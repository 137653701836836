import { getSelectSchema, getStringSchema } from 'src/compass/shared/schemas';
import * as Yup from 'yup';

export const BranchSchema = Yup.object().shape({
  name: Yup.string().required('Branch name is required'),
  cmePayrollExportCode: getStringSchema(50, 'CME Payroll Export Code'),
  lrpPayrollExportCode: getStringSchema(50, 'LRP Payroll Export Code'),
  insAgencyId: getSelectSchema('Agency').notRequired(),
  eHarvestAliasUser: getStringSchema(255, 'eHarvest User').notRequired(),
  isMainOffice: Yup.boolean(),
  isActive: Yup.boolean(),
});