// components
import { CasCRUDTable } from 'src/compass/shared/components';
// types
import { Entity } from 'src/compass/site/util';
import PropertyForm from './_internal/PropertyForm';

type PropertyTabProps = {
  readOnly?: boolean;
};

export default function PropertyTab({ readOnly = false }: PropertyTabProps) {
  return (
    <CasCRUDTable
      entityTitle="Property"
      entity={Entity.commissionProperty}
      columns={[
        {
          field: 'property',
          sortable: false,
        },
        {
          field: 'value',
          sortable: false,
        },
      ]}
      showPagination={false}
      showAddButton={false}
      showEditSingletonButton={!readOnly}
      includeExport={false}
      editForm={PropertyForm}
      canDelete={false}
    />
  );
}
