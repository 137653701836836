import { Typography } from '@mui/material';
import CasLabel from '../CasLabel/CasLabel';
import CasLink from '../CasLink/CasLink';

interface ICasDisplayField {
  label: string | React.ReactElement;
  value: string | JSX.Element;
  className?: string;
  horizontal?: boolean;
  fullWidth?: boolean;
  labelOnClick?: VoidFunction | null;
}

export default function CasDisplayField({
  label,
  value,
  className,
  horizontal = false,
  fullWidth,
  labelOnClick = null,
}: ICasDisplayField) {
  const renderLabel = labelOnClick ? <CasLink onClick={labelOnClick}>{label}</CasLink> : label;  
  
  return (
    <CasLabel
      label={renderLabel}
      className={(horizontal ? 'cas-horizontal-field ' : '') + className}
      sx={fullWidth && { flex: 1 }}
    >
      <Typography>{value}</Typography>
    </CasLabel>
  );
}
