import CasDisplayField from "./CasDisplayField";

interface CasNotesFieldProps {
  value: string | JSX.Element;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  labelOnClick?: VoidFunction | null;
}

export default function CasNotesField({
  label = 'Notes',
  className = '',
  value,
  fullWidth,
  labelOnClick = null,
}: CasNotesFieldProps) {
  return (
    <CasDisplayField
      className={`CasNotesField ${className}`}
      label={label}
      value={value}
      fullWidth={fullWidth}
      labelOnClick={labelOnClick}
    />
  );
}
