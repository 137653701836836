// components
import { CasCRUDTable } from 'src/compass/shared/components';
// types
import { Entity } from 'src/compass/site/util';
import {
  CasRenderBoolTrue,
  CasRenderBoolTrueFalse,
} from 'src/compass/shared/components/CasTable/CellRender/CasRenderBool';
import { CasYesNoSelect } from 'src/compass/application';
// Modal
import BranchForm from './_internal/BranchForm';

type BranchTabProps = {
  readOnly?: boolean;
};

export default function BranchTab({ readOnly = false }: BranchTabProps) {
  return (
    <CasCRUDTable
      entityTitle="Branch"
      entity={Entity.BRANCH}
      showAddButton={!readOnly}
      columns={[
        {
          field: 'name',
          showEditOnclick: !readOnly,
        },
        {
          field: 'isMainOffice',
          label: 'Main office',
          render: CasRenderBoolTrue,
        },
        {
          field: 'insAgencyName',
          label: 'Agency',
        },
        {
          field: 'cmePayrollExportCode',
          label: 'CME P/R Code',
        },
        {
          field: 'lrpPayrollExportCode',
          label: 'LRP P/R Code',
        },
        {
          field: 'eHarvestAliasUser',
          label: 'eHarvest User',
        },
        {
          field: 'isActive',
          label: 'Active',
          render: CasRenderBoolTrueFalse,
        },
      ]}
      filters={[
        {
          name: 'searchName',
          label: 'Name...',
        },
        {
          type: 'component',
          name: 'isActive',
          label: 'Active',
          initialValue: true,
          includeAllOption: true,
          component: CasYesNoSelect,
        },
      ]}
      editForm={BranchForm}
    />
  );
}
