// components
import { CasRenderArrayAsCSV, CasCRUDTable } from 'src/compass/shared/components';
import {
  CasRenderBoolTrue,
  CasRenderBoolTrueFalse,
} from 'src/compass/shared/components/CasTable/CellRender/CasRenderBool';
// types
import { Entity } from 'src/compass/site/util';

import { CasYesNoSelect } from 'src/compass/application';
// Modal
import EmployeeForm from './_internal/EmployeeForm';
import { BranchSelect } from './_internal/BranchSelect';

type EmployeeTabProps = {
  readOnly?: boolean;
};

export default function EmployeeTab({ readOnly = false }: EmployeeTabProps) {
  return (
    <CasCRUDTable
      entityTitle="Employee"
      entity={Entity.EMPLOYEE}
      showAddButton={!readOnly}
      columns={[
        {
          field: 'shortName',
          showEditOnclick: !readOnly,
        },
        {
          field: 'userName',
        },
        {
          field: 'branch',
        },
        {
          field: 'isRiskManager',
          label: 'Is Risk Mgr?',
          render: CasRenderBoolTrue,
        },
        {
          field: 'isSalesOrOther',
          label: 'Is Sales/ Other?',
          render: CasRenderBoolTrue,
        },
        {
          field: 'docusignUser',
        },
        {
          field: 'sceEntryCCEmail',
          label: 'SCE CC',
        },
        {
          field: 'eHarvest',
          label: 'EHarvest Agent',
          render: CasRenderArrayAsCSV,
          sortable: false,
        },
        {
          field: 'payrollEmpNbr',
          label: 'P/R Key',
        },
        {
          field: 'isActive',
          label: 'Active',
          render: CasRenderBoolTrueFalse,
        },
      ]}
      filters={[
        { name: 'searchName', label: 'Name...' },
        {
          type: 'component',
          name: 'branchId',
          label: 'Branch',
          includeAllOption: true,
          component: BranchSelect,
        },
        {
          type: 'component',
          name: 'isActive',
          label: 'Active',
          initialValue: true,
          includeAllOption: true,
          component: CasYesNoSelect,
        },
      ]}
      editForm={EmployeeForm}
    />
  );
}
